import "./application";

//
//
// FIXED HEADER
$(window).on('load scroll resize orientationchange', function () {
  if ($(document).scrollTop()) {
    $("#top_bar").addClass("fixed-top shadow");
    $('#headerimage').css({ 'margin-top': $('#top_bar').height()+'px' });
  } else {
    $("#top_bar").removeClass("fixed-top");
    $('#headerimage').css({ 'margin-top': '0px' });
  }
});


